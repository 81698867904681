<!--  -->
<template>
  <div>
    <div class="header">
      <p>RGB</p>
    </div>
    <div class="main">
      <div class="main-one">
        <div class="box-one">
          <el-input v-model="inputOne" placeholder="请输入十六进制颜色" maxlength="6" clearable>
            <template slot="prepend">#</template>
          </el-input>
        </div>
        <div class="button" @click="changeOne">
          <el-button>转换</el-button>
        </div>
      </div>
      <div
        class="main-two"
        v-clipboard:copy="rgbcolor"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        <div class="two-one">{{rgb}}</div>
        <div class="two-two" :style="{background:rgbcolor}"></div>
      </div>
      <div class="main-three">
        <div class="box-one">
          <el-input
            v-model="r"
            onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');"
            maxlength="3"
            clearable
          >
            <template slot="prepend">rgb</template>
          </el-input>
          <el-input
            v-model="g"
            onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');"
            maxlength="3"
            clearable
          ></el-input>
          <el-input
            v-model="b"
            onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');"
            maxlength="3"
            clearable
          ></el-input>
        </div>
        <div class="button" @click="changeTwo">
          <el-button>转换</el-button>
        </div>
      </div>
      <div
        class="main-two"
        v-clipboard:copy="hex"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        <div class="two-one">{{hex}}</div>
        <div class="two-two" :style="{background:hex}"></div>
      </div>
      <div class="main-four">
        <div class="box-one">
          <el-input v-model="all" placeholder="如 255,255,255" clearable>
            <template slot="prepend">rgb</template>
          </el-input>
        </div>
        <div class="button" @click="changeThree">
          <el-button>转换</el-button>
        </div>
      </div>
      <div
        class="main-two"
        v-clipboard:copy="allcolor"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        <div class="two-one">{{allcolor}}</div>
        <div class="two-two" :style="{background:allcolor}"></div>
      </div>
    </div>

    <!-- 选项颜色 -->
    <div class="mainOne">
      <p>2014 Google Material Design color palettes</p>
      <div class="color">
        <el-row :gutter="24" class="marg">
          <div class="item" v-for="(item,x) in color" :key="x">
            <el-col :span="9">
              <div class="grid-content" :style="{'backgroundColor':item}"></div>
            </el-col>
            <el-col :span="8">
              <div
                class="grid-content txt"
                v-clipboard:copy="item"
                v-clipboard:success="onCopy"
              >{{item}}</div>
            </el-col>
          </div>
        </el-row>
      </div>
    </div>
   <el-backtop clsaa=".page-component__scroll .el-scrollbar__wrap"></el-backtop>
  </div>
  
</template>

<script>
export default {
  components: {},
  data() {
    return {
      inputOne: "",
      rgbcolor: "",
      rgb: "",
      r: "",
      g: "",
      b: "",
      hex: "",
      all: "",
      allcolor: "",
      color: [
        "#FFEBEE",
        "#FFCDD2",
        "#EF9A9A",
        "#E57373",
        "#EF5350",
        "#F44336",
        "#E53935",
        "#D32F2F",
        "#C62828",
        "#B71C1C",
        "#FF8A80",
        "#FF5252",
        "#FF1744",
        "#D50000",
        "#FCE4EC",
        "#F8BBD0",
        "#F48FB1",
        "#F06292",
        "#EC407A",
        "#E91E63",
        "#D81B60",
        "#C2185B",
        "#AD1457",
        "#880E4F",
        "#FF80AB",
        "#FF4081",
        "#F50057",
        "#C51162",
        "#F3E5F5",
        "#E1BEE7",
        "#CE93D8",
        "#BA68C8",
        "#AB47BC",
        "#9C27B0",
        "#8E24AA",
        "#7B1FA2",
        "#6A1B9A",
        "#4A148C",
        "#EA80FC",
        "#E040FB",
        "#D500F9",
        "#AA00FF",
        "#EDE7F6",
        "#D1C4E9",
        "#B39DDB",
        "#9575CD",
        "#7E57C2",
        "#673AB7",
        "#5E35B1",
        "#512DA8",
        "#4527A0",
        "#311B92",
        "#B388FF",
        "#7C4DFF",
        "#651FFF",
        "#6200EA",
        "#E8EAF6",
        "#C5CAE9",
        "#9FA8DA",
        "#7986CB",
        "#5C6BC0",
        "#3F51B5",
        "#3949AB",
        "#303F9F",
        "#283593",
        "#1A237E",
        "#8C9EFF",
        "#536DFE",
        "#3D5AFE",
        "#304FFE",
        "#E3F2FD",
        "#BBDEFB",
        "#90CAF9",
        "#64B5F6",
        "#42A5F5",
        "#2196F3",
        "#1E88E5",
        "#1976D2",
        "#1565C0",
        "#0D47A1",
        "#82B1FF",
        "#448AFF",
        "#2979FF",
        "#2962FF",
        "#E1F5FE",
        "#B3E5FC",
        "#81D4FA",
        "#4FC3F7",
        "#29B6F6",
        "#03A9F4",
        "#039BE5",
        "#0288D1",
        "#0277BD",
        "#01579B",
        "#80D8FF",
        "#40C4FF",
        "#00B0FF",
        "#0091EA",
        "#E0F7FA",
        "#B2EBF2",
        "#80DEEA",
        "#4DD0E1",
        "#26C6DA",
        "#00BCD4",
        "#00ACC1",
        "#0097A7",
        "#00838F",
        "#006064",
        "#84FFFF",
        "#18FFFF",
        "#00E5FF",
        "#00B8D4",
        "#E0F2F1",
        "#B2DFDB",
        "#80CBC4",
        "#4DB6AC",
        "#26A69A",
        "#009688",
        "#00897B",
        "#00796B",
        "#00695C",
        "#004D40",
        "#A7FFEB",
        "#64FFDA",
        "#1DE9B6",
        "#00BFA5",
        "#E8F5E9",
        "#C8E6C9",
        "#A5D6A7",
        "#81C784",
        "#66BB6A",
        "#4CAF50",
        "#43A047",
        "#388E3C",
        "#2E7D32",
        "#1B5E20",
        "#B9F6CA",
        "#69F0AE",
        "#00E676",
        "#00C853",
        "#F1F8E9",
        "#DCEDC8",
        "#C5E1A5",
        "#AED581",
        "#9CCC65",
        "#8BC34A",
        "#7CB342",
        "#689F38",
        "#558B2F",
        "#33691E",
        "#CCFF90",
        "#B2FF59",
        "#76FF03",
        "#64DD17",
        "#F9FBE7",
        "#F0F4C3",
        "#E6EE9C",
        "#DCE775",
        "#D4E157",
        "#CDDC39",
        "#C0CA33",
        "#AFB42B",
        "#9E9D24",
        "#827717",
        "#F4FF81",
        "#EEFF41",
        "#C6FF00",
        "#AEEA00",
        "#FFFDE7",
        "#FFF9C4",
        "#FFF59D",
        "#FFF176",
        "#FFEE58",
        "#FFEB3B",
        "#FDD835",
        "#FBC02D",
        "#F9A825",
        "#F57F17",
        "#FFFF8D",
        "#FFFF00",
        "#FFEA00",
        "#FFD600",
        "#FFF8E1",
        "#FFECB3",
        "#FFE082",
        "#FFD54F",
        "#FFCA28",
        "#FFC107",
        "#FFB300",
        "#FFA000",
        "#FF8F00",
        "#FF6F00",
        "#FFE57F",
        "#FFD740",
        "#FFC400",
        "#FFAB00",
        "#FFF3E0",
        "#FFE0B2",
        "#FFCC80",
        "#FFB74D",
        "#FFA726",
        "#FF9800",
        "#FB8C00",
        "#F57C00",
        "#EF6C00",
        "#E65100",
        "#FFD180",
        "#FFAB40",
        "#FF9100",
        "#FF6D00",
        "#FBE9E7",
        "#FFCCBC",
        "#FFAB91",
        "#FF8A65",
        "#FF7043",
        "#FF5722",
        "#F4511E",
        "#E64A19",
        "#D84315",
        "#BF360C",
        "#FF9E80",
        "#FF6E40",
        "#FF3D00",
        "#DD2C00",
        "#EFEBE9",
        "#D7CCC8",
        "#BCAAA4",
        "#A1887F",
        "#8D6E63",
        "#795548",
        "#6D4C41",
        "#5D4037",
        "#4E342E",
        "#3E2723",
        "#FAFAFA",
        "#F5F5F5",
        "#EEEEEE",
        "#E0E0E0",
        "#BDBDBD",
        "#9E9E9E",
        "#757575",
        "#616161",
        "#424242",
        "#212121",
        "#ECEFF1",
        "#CFD8DC",
        "#B0BEC5",
        "#90A4AE",
        "#78909C",
        "#607D8B",
        "#546E7A",
        "#455A64",
        "#37474F",
        "#263238",
        "#000000"
      ]
    };
  },
  computed: {},
  watch: {},
  methods: {
    changeOne: function() {
      this.$ga.event("click", "转换rgb", "00687824");
      if (this.inputOne.length == 3 || this.inputOne.length == 6) {
        this.rgbcolor = this.toRgb(this.inputOne);
      } else {
        this.$message.error("十六进制代码长度为3位或6位");
      }
    },
    changeTwo: function() {
      this.$ga.event("click", "转换hex", "00687825");
      if (this.r !== "" && this.g !== "" && this.b !== "") {
        var a = `rgb(${this.r},${this.g},${this.b})`;
        this.hex = this.toHex(a);
      } else {
        this.$message.error("请检查输入的内容是否为空");
      }
    },
    changeThree: function() {
      if (this.all !== "") {
        var a = `rgb(${this.all})`;
        this.allcolor = this.toHex(a);
      } else {
        this.$message.error("请检查输入的内容是否为空");
      }
    },
    copy: function(e) {},
    toRgb: function(hex) {
      var rgb = [];
      if (hex.length === 3) {
        // 处理 "#abc" 成 "#aabbcc"
        hex = hex.replace(/(.)/g, "$1$1");
      }
      hex.replace(/../g, function(color) {
        rgb.push(parseInt(color, 0x10)); //按16进制将字符串转换为数字
      });
      if (rgb.includes(NaN)) {
        this.$message.error("请输入正确的十六进制");
        return;
      } else {
        this.rgb = rgb.join(",");
      }
      return "rgb(" + rgb.join(",") + ")";
    },
    toHex: function(rgb) {
      // rgb(x, y, z)
      var color = rgb.toString().match(/\d+/g); // 把 x,y,z 推送到 color 数组里
      var hex = "#";
      for (var i = 0; i < 3; i++) {
        // 'Number.toString(16)' 是JS默认能实现转换成16进制数的方法.
        // 'color[i]' 是数组，要转换成字符串.
        // 如果结果是一位数，就在前面补零。例如： A变成0A
        hex += ("0" + Number(color[i]).toString(16)).slice(-2);
      }
      return hex;
    },
    onCopy: function(e) {
      this.$message({
        message: "复制成功",
        type: "success"
      });
    },
    onError: function(e) {
      // this.$message({
      //     message: '复制成功',
      //     type: 'success'
      //   });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.$ga.page("/");
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.header {
  padding: 0 150px;
}
.main {
  padding: 0 150px;
  .main-one {
    display: flex;
    width: 50%;
    .box-one {
      width: 100%;
    }
  }
  .main-two {
    display: flex;
    padding: 1rem 0;
    margin: 1rem 0;
    .two-two {
      width: 20%;
      margin-left: 1rem;
    }
  }
  .main-three {
    display: flex;
    .box-one {
      display: flex;
    }
  }
  .main-four {
    display: flex;
  }
}
.mainOne {
  padding: 0 150px;
  .color {
    .marg {
      margin-left: 0 !important;
      margin-right: 0 !important;
      .item {
        margin-top: 0.5rem;
      }
      .grid-content {
        height: 2rem;
      }
      .test {
        // background-color: orange;
      }
      .txt {
        text-align: center;
        line-height: 2rem;
      }
    }
  }
}
</style>