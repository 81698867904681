import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipboard from 'vue-clipboard2'
import VueAnalytics from 'vue-analytics'
Vue.config.productionTip = false;
Vue.use(VueClipboard)
Vue.use(ElementUI);
Vue.use(VueAnalytics, {
  id:'UA-111539033-4',
  // router, // 确保路由切换时可以自动统计
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
